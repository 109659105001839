import '../css/MainPicture.css'

export function MainPicture() {
    return (
        <div className='main-picture'>
            <div className='text-div'>
                <span>РОССИЙСКИЙ</span>
                <span>ПРОИЗВОДИТЕЛЬ</span>
                <span>ПРОВОЛОКИ</span>
                {/*<div className='btn-div'>*/}
                {/*    <button>Узнать больше</button>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}